body.dark-mode .row-user-setting,
body.dark-mode .row-setting-media {
  background: rgb(33 33 56 / 90%) !important;
}
body.dark-mode .button-close-user-setting,
body.dark-mode .button-close-sidebar,
body.dark-mode .activesidebar i,
body.dark-mode .button-mute-sidebar i,
body.dark-mode .dropdown-button-option button i,
body.dark-mode .row-content-setting-media h2 i,
body.dark-mode .button-close-setting-media,
body.dark-mode .button-w-onboarding,
body.dark-mode .button-up-onboarding,
body.dark-mode .row-button-onboarding span,
body.dark-mode .button-right-onboarding,
body.dark-mode .button-left-onboarding,
body.dark-mode .button-down-onboarding,
body.dark-mode .button-dragging {
  filter: brightness(0) invert(1) !important;
}
body.dark-mode .row-title-user-setting h2,
body.dark-mode .column-setting-linkpad h3,
body.dark-mode .column-setting-linkpad p,
body.dark-mode .mode-user-setting span,
body.dark-mode .activesidebar span,
body.dark-mode .column-data-user h2,
body.dark-mode .titlewaiting,
body.dark-mode .column-data-waiting h2,
body.dark-mode .button-mute-sidebar,
body.dark-mode .dropdown-button-option button,
body.dark-mode .column-content-pinned-message h2,
body.dark-mode .column-message h2,
body.dark-mode .text-message,
body.dark-mode .row-notification-message-sidebar ul li h3,
body.dark-mode .row-notification-message-sidebar ul li,
body.dark-mode .row-content-setting-media h2,
body.dark-mode .field-setting-media label,
body.dark-mode .column-onboarding p,
body.dark-mode .row-title-onboarding h2 ,
body.dark-mode .roomtitle ,
body.dark-mode .activesidebar h3
{
  color: #fff !important;
}
body.dark-mode .row-fiel-user-setting input {
  background: #27273e !important;
  color: #fff !important;
  border: 0.1vw solid #27273e !important;
}
body.dark-mode .field-setting-media select {
  background: #27273e url('/assets/images/icon-select-dark-mode.svg') no-repeat 95% / 0.6vw !important;
  color: #fff !important;
  border: 0.1vw solid #27273e !important;
}
body.dark-mode .logolinkpad {
  background: url('/assets/images/background-back-linkpad-dark-mode.svg') no-repeat center bottom /
    contain !important;
}
body.dark-mode .column-sidebar,
body.dark-mode .row-send-message-sidebar {
  background: #212138 !important;
}

body.dark-mode .row-menu-sidebar,
body.dark-mode .row-pinned-message {
  border-bottom: 0.1vw solid #3c3d53 !important;
}
body.dark-mode .row-menu-sidebar a {
  border-top: 0.1vw solid #212138 !important;
  border-left: 0.1vw solid #212138 !important;
  border-right: 0.1vw solid #212138 !important;
}

body.dark-mode .row-menu-sidebar a.activesidebar {
  border-top: 0.1vw solid #3c3d53 !important;
  border-left: 0.1vw solid #3c3d53 !important;
  border-right: 0.1vw solid #3c3d53 !important;
  border-bottom: 0.1vw solid #212138 !important;
}

body.dark-mode .button-mute-sidebar,
body.dark-mode .dropdown-button-option,
body.dark-mode .box-message-sidebar {
  background: #43445b !important;
}
body.dark-mode .dropdown-button-option:before {
  border-bottom: 10px solid #43445b !important;
}
body.dark-mode .button-upload-sidebar {
  background-color: #1e2d4c !important;
}
body.dark-mode .button-unlocked-sidebar {
  color: #fff !important;
}
body.dark-mode .button-send-message-all {
  background-color: #1e2d4c !important;
}
body.dark-mode .button-setting-sidebar {
  background-color: #1e2d4c !important;
}
body.dark-mode .button-locked-sidebar {
  color: #fff !important;
}
body.dark-mode .action-video-setting,
body.dark-mode .action-audio-setting {
  background-color: #316467 !important;
}
body.dark-mode .action-video-setting.active,
body.dark-mode .action-audio-setting.active {
  background-color: #723d4e !important;
}
body.dark-mode .container {
  background: url('/assets/images/background-dark-mode.jpg') no-repeat center / cover !important;
}
body.dark-mode .column-onboarding {
  background: none !important;
  box-shadow: 0 0 0 !important;
}
body.dark-mode .icon-hand,
body.dark-mode .icon-like,
body.dark-mode .icon-hande {
  display: none !important;
}
body.dark-mode .row-button-onboarding button {
  border: 0.1vw solid #fff !important;
}
body.dark-mode .row-dots-onboarding ul,
body.dark-mode .row-button-enter {
  justify-content: flex-start !important;
}
body.dark-mode .column-onboarding {
  width: 40vw !important;
}
body.dark-mode .row-list-placed-items ul li span {
  color: #fff !important;
}
body.dark-mode .button-edit-placed-item {
  filter: brightness(0) invert(1) !important;
}
body.dark-mode .button-edit-placed-item:hover {
  filter: inherit !important;
}
