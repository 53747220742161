:root {
  --primary-color: #0091ff;
  --secondary-color: #2351e5;
  --cancel-primary-color: #e9eaf0;
  --cancel-secondary-color: #c8c9cf;
}

/* Reset styles for common elements */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  border: 0;
  font-style: normal;
  font-weight: normal;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}

html,
body {
  padding: 0;
  margin: 0;
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body .ui-autocomplete {
  max-height: 8rem !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

img {
  border: none;
  display: block;
}

p,
li {
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

*:focus {
  outline: none;
}

* {
  box-sizing: border-box;
}

input,
textarea,
button,
select {
  padding: 0;
  margin: 0;
  appearance: none;
}

::-webkit-scrollbar {
  background: #f1f1f1;
  width: 0.35vw;
}

::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
}

::placeholder {
  color: #a6abc1;
}

input[type='checkbox'] {
  width: 0.95vw;
  height: 0.95vw;
  min-width: 0.95vw;
  min-height: 0.95vw;
  cursor: pointer;
  background: #fff;
  border: 1px solid #d2d5e0;
  border-radius: 0.1vw;
  margin-right: 0.5vw;
}
input[type='checkbox']:checked {
  border: 1px solid var(--primary-color) !important;
  background: var(--primary-color) url('/assets/images/icon-check.svg') no-repeat center / contain !important;
}

.flexbox {
  display: flex;
  flex-flow: row wrap;
}

.input-disabled {
   pointer-events: none;
   filter: grayscale(100%) opacity(50%);
 }

/* Media queries for responsive design */
@media screen and (max-width: 760px), (max-width: 960px) and (orientation: landscape) {
  ::-webkit-scrollbar {
    width: 3px;
  }

  input[type='checkbox'] {
    width: 1.4em;
    height: 1.4em;
    min-width: 1.4em;
    min-height: 1.4em;
    border-radius: 3px;
    margin-right: 0.75em;
  }

  .alert-full-width {
    font: 0.75em poppins-regular !important;
    position: absolute;
  }
}

@media screen and (max-width: 360px) {
  body {
    font-size: 14.75px;
  }
}

@media screen and (max-width: 320px) {
  body {
    font-size: 13.75px;
  }
}

/* Font-face declarations */
@font-face {
  font-family: 'poppins-bold';
  src:
    url('/assets/fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Poppins-Bold.woff') format('woff'),
    url('/assets/fonts/Poppins-Bold.ttf') format('truetype'),
    url('/assets/fonts/Poppins-Bold.svg#Poppins-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'poppins-regular';
  src:
    url('/assets/fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Poppins-Regular.woff') format('woff'),
    url('/assets/fonts/Poppins-Regular.ttf') format('truetype'),
    url('/assets/fonts/Poppins-Regular.svg#Poppins-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'poppins-medium';
  src:
    url('/assets/fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Poppins-Medium.woff') format('woff'),
    url('/assets/fonts/Poppins-Medium.ttf') format('truetype'),
    url('/assets/fonts/Poppins-Medium.svg#Poppins-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Class-based styles */
.requiredfield {
  border: 1px solid #eb5757 !important;
}

.requiredtext {
  color: #eb5757 !important;
}

.requiredtext a {
  color: #eb5757 !important;
}

.alert-full-width {
  width: 100%;
  background-color: tomato;
  color: white;
  font: 0.885vw/1vw poppins-regular;
  padding: 0.75rem;
  text-align: center;
}

.modal-select-organization {
   position: fixed;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 99;
 }

 

.modal-layer-create-room {
   position: fixed;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   background: #212138;
   opacity: 0.7;
 }
 
 .modal-content-create-room {
   width: 21vw;
   background: #fff;
   padding: 1.75vw;
   z-index: 9;
   position: absolute;
   top: 5vw;
   left: 0;
   right: 0;
   margin: 0 auto;
   border-radius: 0.75vw;
 }
 .modal-content-create-room-center {
   top:  unset !important;
 }

 .modal-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
 }
 
 .modal-header h2 {
   font: 0.885vw/1vw poppins-bold;
   color: #212138;
 }
 
 .close-modal-create-room {
   width: 0.636vw;
   height: 0.636vw;
   background: url('/assets/images/icon-modal.svg') no-repeat center right / 100%;
   border: none;
   cursor: pointer;
 }
 
 .modal-body {
   width: 100%;
   margin-top: 1.2vw;
 }
 
 .row-field-create-room {
   width: 100%;
   margin-bottom: 0.9vw;
   border: 0.1vw solid #d2d5e0;
   border-radius: 0.3vw;
   position: relative;
 }
 
 .row-field-create-room > input[type='text'],
 .row-field-create-room > input[type='password'],
 .row-field-create-room > input[type='email'],
 .row-field-create-room > input[type='tel'] {
   font: 0.729vw/1vw poppins-regular;
   color: #212138;
   height: 2.604vw;
   width: 100%;
   border: none;
   border-radius: 0.3vw;
   padding-left: 0.7vw;
   padding-right: 3vw;
 }
 
 .row-field-create-room select {
   font: 0.729vw/1vw poppins-regular;
   color: #212138;
   height: 2.604vw;
   width: 100%;
   border: none;
   border-radius: 0.3vw;
   padding-left: 0.7vw;
   padding-right: 3vw;
   color: #a6abc1;
   background: url('/assets/images/icon-select.svg') no-repeat 95% / 0.6vw;
 }
 .row-checkbox {
     display: flex;
     align-items: center;
 
 }
 .modal-footer {
   width: 100%;
   display: flex;
   justify-content: space-between;
 }
 
 .modal-footer button {
   font: 0.729vw/1vw poppins-bold;
   color: #212138;
   width: 48.5%;
   height: 2.604vw;
   border: none;
   border-radius: 0.3vw;
   background: #e9eaf0;
   cursor: pointer;
 }
 
 .modal-footer button:hover {
   background: #dedfe8;
 }
 
 .modal-button-save {
   background: var(--primary-color) !important;
   color: #fff !important;
 }
 
 .modal-button-save:hover {
   background: var(--secondary-color) !important;
 }
 
 .modal-button-clear-password {
   font: 0.729vw/1vw poppins-bold;
   color: #fff;
   width: 100%;
   height: 2.604vw;
   border: none;
   border-radius: 0.3vw;
   background: var(--primary-color);
   cursor: pointer;
   margin-bottom: 0.9vw;
 }
 
 .modal-button-clear-password:hover {
   background: var(--secondary-color) !important;
 }